export default ($axios) => ({
  getSearchLists(params) {
    return $axios.$post('/businesscenter/goods/listed/getListedAttr', params)
  },
  getBidListedAttr() {
    return $axios.$post('/businesscenter/goods/listed/getBidListedAttr')
  },
  getGoodsLists(params, cancelToken) {
    return $axios.$post('/businesscenter/goods/listed/search', params, {
      cancelToken,
      showMessage: false
    })
  },
  getSearchDetails(params) {
    return $axios.$post('/businesscenter/goods/listed/searchDetails', params, { showMessage: false })
  },
  getPerformance(params) {
    return $axios.$get('/businesscenter/goods/listed/getPerformance', { params })
  },
  getWarranty(params) {
    return $axios.$get('/businesscenter/goods/listed/getWarranty', { params })
  },
  getNgChapter(params) {
    return $axios.$get('/businesscenter/warranty/downNgChapter', { params, showMessage: false })
  },
  getGoodsInfo(params) {
    return $axios.$get('/businesscenter/goods/listed/goodsInfo', { params })
  },
  getStatistics(params, cancelToken) {
    return $axios.$post('/businesscenter/goods/listed/statistics', params, {
      cancelToken
    })
  },
  getSearchDetailsList(params) {
    return $axios.$post('/businesscenter/goods/listed/searchDetailsList', params)
  },
  // 专区切换
  changeZone(params) {
    return $axios.$get('/businesscenter/goods/zone/changeZone', { params,showMessage: false })
  }
})
